import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changePassword, changeUserData } from '../../../redux/action/auth';
import { checkPayUrl, fetchRequest } from '../../../redux/action/fetchTools.js';
import { toggleNotification } from '../../../redux/action/notifications.js';
import MainBtn from '../../layout/MainBtn/MainBtn.jsx';
import BalanceBlock from './BalanceBlock/index.jsx';
import {
    getCommonButtons,
    getNotificationButtons,
    getPassFields,
    getUserFields,
} from './config.js';
import NotificationsButtonBox from './NotificationButtonBox';
import SettingsForm from './SettingsForm/SettingsForm';
import styles from './SettingsPageBlock.module.scss';

function SettingsPageBlock() {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const changePasswordLoading = useSelector(
        (state) => state.auth.changePasswordLoading
    );
    const changePasswordError = useSelector(
        (state) => state.auth.changePasswordError
    );

    const editDataLoading = useSelector((state) => state.auth.editDataLoading);
    const editDataError =
        useSelector((state) => state.auth.editDataError) || [];

    const isPushEnabled = useSelector(
        (state) => state.notifications.isPushEnabled
    );
    const tgNotifications =
        useSelector((state) => state.notifications.tg) || {};
    const webNotifications =
        useSelector((state) => state.notifications.web) || {};

    const changeUserFields = getUserFields(user);
    const commonButtons = getCommonButtons(user);
    const changePassFields = getPassFields();
    const notificationButtons = getNotificationButtons();

    const onChangePassword = (formData, clb) => {
        dispatch(changePassword({ ...formData, email: user.email }, clb));
    };

    const onEditUserData = (formData, clb) => {
        dispatch(changeUserData(formData, true, clb));
    };

    return (
        <div className={styles['settingsBlock']}>
            <h5
                className={`${styles['settingsBlock__routeTitle']} pageRouteTitle`}
            >
                Главная &gt; Settings
            </h5>
            <div className={styles['settingsBlock__main']}>
                <div className={styles['settingsBlock__mainBlock']}>
                    <div className={styles['settingsBlock__box']}>
                        <h6 className={styles['settingsBlock__boxSubtitle']}>
                            Смена данных
                        </h6>
                        <h4 className={styles['settingsBlock__boxTitle']}>
                            Общие
                        </h4>
                        {user ? (
                            <SettingsForm
                                fields={changeUserFields}
                                onSubmit={onEditUserData}
                                error={editDataError}
                                loading={editDataLoading}
                                submitSuccessText={'Данные обновлены.'}
                                buttons={commonButtons}
                            />
                        ) : null}
                    </div>
                    <div className={styles['settingsBlock__box']}>
                        <h6 className={styles['settingsBlock__boxSubtitle']}>
                            Смена пароля
                        </h6>
                        <h4 className={styles['settingsBlock__boxTitle']}>
                            Безопасность
                        </h4>
                        {user ? (
                            <SettingsForm
                                fields={changePassFields}
                                onSubmit={onChangePassword}
                                error={changePasswordError}
                                submitSuccessText={'Пароль обновлен.'}
                                loading={changePasswordLoading}
                            />
                        ) : null}
                    </div>
                </div>
                <div className={styles['settingsBlock__box']}>
                    <div className={styles['settingsBlock__boxHeader']}>
                        <h6 className={styles['settingsBlock__boxSubtitle']}>
                            Для пополнения баланса переведите монеты на адрес
                            контракта.
                        </h6>
                        <MainBtn
                            onClick={() => {
                                const res = fetchRequest(checkPayUrl, 'POST', {
                                    id: user.id,
                                });
                                console.log(res);
                            }}
                        >
                            Проверить оплату
                        </MainBtn>
                    </div>

                    <h4 className={styles['settingsBlock__boxTitle']}>
                        Баланс
                    </h4>
                    <BalanceBlock />
                </div>
                <div className={styles['settingsBlock__box']}>
                    <h6 className={styles['settingsBlock__boxSubtitle']}>
                        Примечание: обязательно разрешайте уведомления, когда
                        браузер запрашивает ваше разрешение.
                    </h6>

                    <h4 className={styles['settingsBlock__boxTitle']}>
                        <span>Уведомления</span>
                        <p>
                            <span>Telegram</span>
                            <span>Browser</span>
                        </p>
                    </h4>
                    {
                        <div
                            className={
                                styles['settingsBlock__boxNotifications']
                            }
                        >
                            {notificationButtons.map((button, index) => (
                                <NotificationsButtonBox
                                    key={index}
                                    title={button.title}
                                    description={button.description}
                                    isPushEnabled={isPushEnabled}
                                    onTogglePush={button.onTogglePush}
                                    isWebEnabled={
                                        webNotifications[button.section]
                                    }
                                    onToggleWeb={() => {
                                        dispatch(
                                            toggleNotification(
                                                'web',
                                                button.section
                                            )
                                        );
                                    }}
                                    isTelegramEnabled={
                                        tgNotifications[button.section]
                                    }
                                    onToggleTelegram={() => {
                                        dispatch(
                                            toggleNotification(
                                                'tg',
                                                button.section
                                            )
                                        );
                                    }}
                                />
                            ))}
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default SettingsPageBlock;
