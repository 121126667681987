import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { FixedSizeGrid as Grid } from 'react-window';

import { arbitrageTypes, subscriptionTypes } from '../../../constants';
import { useSocket } from '../../../hooks/useSocket';
import { getArbitrage } from '../../../redux/action/arbitrage';
import {
    authConfig,
    fetchRequest,
    reportUrl,
} from '../../../redux/action/fetchTools';
import { changeFilters, setAutoRefresh } from '../../../redux/action/filters';
import { arbitragePagePath } from '../../../router/path';
import { filterType } from '../../../utils/functions/filters';
import FiltersBlock from '../../global/FiltersBlock';
import ApplyPopup from '../../layout/ApplyPopup';
import DataLoader from '../../layout/DataLoader/DataLoader';
import styles from './ArbitragePageList.module.scss';
import ArbitragePageListItem from './ArbitragePageListItem/ArbitragePageListItem';

const ArbitragePageList = ({
    socketSubscrition,
    restApiFunction,
    arbitrageType,
}) => {
    const { hash } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const user = useSelector((state) => state.auth.user);
    const data = useSelector((state) => state.arbitrage.data);
    const filters = useSelector((state) => state.filters.filters);
    const loading = useSelector((state) => state.arbitrage.getLoading);
    const autoRefresh = useSelector((state) => state.filters.autoRefresh);

    const userWallets = {
        solana: user?.SolWallet,
        eth: user?.EthWallet,
        bsc: user?.BscWallet,
    };

    const [isReportSended, setIsReportSended] = useState(false);
    const reportSendedModalIntervalRef = useRef(null);

    const containerRef = useRef(null);
    const [containerWidth, setContainerWidth] = useState(0);
    const [containerHeigth, setContainerHeigth] = useState(0);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const paddingSize = 10;

    useEffect(() => {
        const updateSize = () => {
            if (containerRef.current) {
                setContainerWidth(containerRef.current.offsetWidth);
                setContainerHeigth(containerRef.current.offsetHeight);
            }

            setWindowWidth(window.innerWidth);
        };

        updateSize();

        const observer = new ResizeObserver(() => {
            updateSize();
        });

        if (containerRef.current) {
            observer.observe(containerRef.current);
        }

        window.addEventListener('resize', updateSize);

        return () => {
            if (containerRef.current) {
                observer.unobserve(containerRef.current);
            }
            observer.disconnect();
            window.removeEventListener('resize', updateSize);
        };
    }, []);

    const onSuccessFilterApply = useCallback(restApiFunction, [
        restApiFunction,
    ]);

    useEffect(() => {
        dispatch(setAutoRefresh());

        if (!hash) {
            navigate(`${arbitragePagePath}#free`);
        }
    }, [dispatch, hash, navigate]);

    useEffect(() => {
        if (isReportSended) {
            reportSendedModalIntervalRef.current = setTimeout(() => {
                setIsReportSended(false);
            }, 2000);
        } else {
            if (reportSendedModalIntervalRef.current)
                clearInterval(reportSendedModalIntervalRef.current);
        }
    }, [isReportSended]);

    useSocket(
        useCallback((payload) => dispatch(getArbitrage(payload)), [dispatch]),
        useCallback(
            () => dispatch(restApiFunction()),
            [dispatch, restApiFunction]
        ),
        socketSubscrition,
        autoRefresh
    );

    const onSendReportMessage = useCallback(
        async (Item) => {
            const reqBody = {
                kind: `${arbitrageType.toLowerCase().replace(' ', '_')}_${hash.slice(1)}`,
                user: user,
                object: Item,
            };

            try {
                const res = await fetchRequest(
                    reportUrl,
                    'POST',
                    reqBody,
                    authConfig()
                );

                if (res.status === 200) {
                    setIsReportSended(true);
                }
            } catch (error) {
                console.log(error);
            }
        },
        [arbitrageType, hash, user]
    );

    const onAddToBlackList = useCallback(
        (symbol) => {
            dispatch(
                changeFilters({
                    ...filters,
                    blacklist: [...filters.blacklist, symbol],
                })
            );
        },
        [dispatch, filters.blacklist]
    );

    const onAddToHidden = useCallback(
        (item) => {
            const newItem = {
                symbol: item.Symbol,
                ex1: item.Ex1,
                ex2: item.Ex2,
                created_at: Date.now(),
            };
            const oldData = filters.hidden || [];
            if (
                !oldData.find(
                    (oldItem) =>
                        oldItem.symbol === item.Symbol &&
                        oldItem.ex1 === item.Ex1 &&
                        oldItem.ex2 === item.Ex2
                )
            ) {
                dispatch(
                    changeFilters({ ...filters, hidden: [...oldData, newItem] })
                );
            }
        },
        [dispatch, filters.hidden]
    );

    const filteredData = data.filter((item) => {
        return filterType(item, hash);
    });

    const columnCount = windowWidth > 1376 ? 3 : windowWidth > 768 ? 2 : 1;
    const columnWidth =
        containerWidth / columnCount -
        paddingSize * (windowWidth > 1376 ? 1.3 : windowWidth > 768 ? 2 : 0);
    const gridWidth =
        containerWidth - paddingSize * (windowWidth > 768 ? columnCount : 0);
    const gridHeigth =
        containerHeigth - paddingSize * (windowWidth > 768 ? 4 : 0);
    const rowHeigth =
        (hash.slice(1) === arbitrageTypes.cexToDex ? 558 : 455) +
        paddingSize * 2;

    const Cell = ({ columnIndex, rowIndex, style }) => {
        const itemIndex = rowIndex * columnCount + columnIndex;
        const item = filteredData[itemIndex];

        if (!item) {
            return <></>;
        }

        return (
            <div
                style={{
                    ...style,
                    left: style.left + paddingSize,
                    top: style.top + paddingSize,
                    width: style.width - paddingSize * 2,
                    height: style.height - paddingSize * 2,
                }}
            >
                <ArbitragePageListItem
                    key={rowIndex + columnIndex}
                    onSendReportMessage={onSendReportMessage}
                    onAddToBlackList={onAddToBlackList}
                    onAddToHidden={onAddToHidden}
                    isArb={user?.subscription === subscriptionTypes.arb}
                    isCexToDex={hash.slice(1) === arbitrageTypes.cexToDex}
                    isDexToDex={hash.slice(1) === arbitrageTypes.dexToDex}
                    item={item && item}
                    userId={user.id}
                    userWallets={userWallets}
                    {...item}
                />
            </div>
        );
    };

    return (
        <div className={styles['arbitrageList']}>
            <FiltersBlock onSuccessFilterApply={onSuccessFilterApply} />
            <ApplyPopup
                isOpened={isReportSended}
                onClose={() => setIsReportSended(false)}
                text="Жалоба отправлена"
            />
            <div
                ref={containerRef}
                className={styles['arbitrageList__container']}
            >
                {filteredData.length > 0 ? (
                    <Grid
                        className={styles.grid}
                        columnCount={columnCount}
                        columnWidth={columnWidth}
                        height={gridHeigth}
                        overscanRowCount={5}
                        rowCount={Math.ceil(filteredData.length / columnCount)}
                        rowHeight={rowHeigth}
                        width={gridWidth}
                    >
                        {Cell}
                    </Grid>
                ) : (
                    <DataLoader
                        loading={loading}
                        isEmpty={!filteredData.length}
                    />
                )}
            </div>
        </div>
    );
};

export default ArbitragePageList;
