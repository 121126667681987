import React from 'react';
import { crossIcon, eyeIcon, reportFakeIcon } from '../../../../../assets/svg';
import { formatDateWithTime } from '../../../../../utils/functions/date';
import Svg from '../../../../layout/Svg/Svg';
import styles from './styles.module.scss';

const FundingListItem = ({
    Coin,
    item,
    onSendReportMessage,
    onAddToHidden,
    onAddToBlacklist,
    Ex1Price,
    Ex1Funding,
    Ex1,
    Ex2Price,
    Ex2Funding,
    Ex2,
    DiffPrice,
    Profit,
    Commission,
    NextFundingTimeEx1,
    NextFundingTimeEx2,
    Link1,
    Link2,
}) => {
    return (
        <div className={styles['fundingListItem']}>
            <div className={styles['fundingListItem__header']}>
                <h2 className={styles['fundingListItem__title']}>
                    {Coin.toUpperCase().replace('USDT', '')}
                </h2>

                <div className={styles['fundingListItem__headerBtns']}>
                    <button
                        className={styles['fundingListItem__hiderBtn']}
                        onClick={() => onSendReportMessage(item)}
                    >
                        <Svg
                            id={reportFakeIcon}
                            className={styles['fundingListItem__reportIcon']}
                        />
                    </button>
                    <button
                        onClick={onAddToHidden}
                        className={styles['fundingListItem__hiderBtn']}
                    >
                        <Svg
                            className={styles['fundingListItem__hideIcon']}
                            id={eyeIcon}
                        />
                    </button>
                    <button
                        className={styles['fundingListItem__hiderBtn']}
                        onClick={onAddToBlacklist}
                    >
                        <Svg
                            className={styles['fundingListItem__crossIcon']}
                            id={crossIcon}
                        />
                    </button>
                </div>
            </div>
            <div
                className={`${styles['fundingListItem__resultBlock']} ${styles['fundingListItem__resultBlock_output']}`}
            >
                {Ex1Funding !== undefined && Ex1Funding !== null  && (
                    <p className={styles['fundingListItem__resultBlockText']}>
                        <span
                            className={
                                styles['fundingListItem__resultBlockText_green']
                            }
                        >
                            FundingRate:{' '}
                        </span>
                        {Ex1Funding}
                    </p>
                )}
                <p className={styles['fundingListItem__resultBlockText']}>
                    <span
                        className={
                            styles['fundingListItem__resultBlockText_green']
                        }
                    >
                        ASK:{' '}
                    </span>
                    {Ex1Price}
                </p>

                <p className={styles['fundingListItem__resultBlockText']}>
                    <span
                        className={
                            styles['fundingListItem__resultBlockText_green']
                        }
                    >
                        Купить:{' '}
                    </span>
                    <a
                        className={styles["arbitrageListItem__resultBlockLinkText"]}
                        target={"_blank"}
                        rel="noreferrer"
                        href={Link1}>{Ex1}</a>
                </p>
                <p className={styles['fundingListItem__resultBlockText']}>
                    <span
                        className={
                            styles['fundingListItem__resultBlockText_green']
                        }
                    >
                        Время начисления:{' '}
                    </span>
                    {formatDateWithTime(NextFundingTimeEx1)}
                </p>
            </div>
            <div
                className={`${styles['fundingListItem__resultBlock']} ${styles['fundingListItem__resultBlock_input']}`}
            >
                {Ex2Funding !== undefined && Ex2Funding !== null  && (
                <p className={styles['fundingListItem__resultBlockText']}>
                    <span
                        className={
                            styles['fundingListItem__resultBlockText_red']
                        }
                    >
                        FundingRate:{' '}
                    </span>
                    {Ex2Funding}
                </p>
                )}
                <p className={styles['fundingListItem__resultBlockText']}>
                    <span
                        className={
                            styles['fundingListItem__resultBlockText_red']
                        }
                    >
                        BID:{' '}
                    </span>
                    {Ex2Price}
                </p>

                <p className={styles['fundingListItem__resultBlockText']}>
                    <span
                        className={
                            styles['fundingListItem__resultBlockText_red']
                        }
                    >
                        Продать:{' '}
                    </span>
                    <a
                        className={styles["arbitrageListItem__resultBlockLinkText"]}
                        target={"_blank"}
                        rel="noreferrer"
                        href={Link2}>{Ex2}</a>
                </p>
                <p className={styles['fundingListItem__resultBlockText']}>
                    <span
                        className={
                            styles['fundingListItem__resultBlockText_red']
                        }
                    >
                        Время начисления:{' '}
                    </span>
                    {formatDateWithTime(NextFundingTimeEx2)}
                </p>
            </div>
            <p className={styles['fundingListItem__profitText']}>
                Общая комиссия:
                <span className={styles['fundingListItem__profitText_blue']}>
                    {' '}
                    {Commission.toFixed(4)}%
                </span>
            </p>
            <p className={styles['fundingListItem__profitText']}>
                Спред входа:
                <span className={styles['fundingListItem__profitText_blue']}>
                    {' '}
                    {DiffPrice.toFixed(4)}%
                </span>
            </p>
            <p className={styles['fundingListItem__profitText']}>
                Profit:
                <span className={styles['fundingListItem__profitText_blue']}>
                    {' '}
                    {Profit.toFixed(4)}%
                </span>
            </p>
        </div>
    );
};

export default FundingListItem;
