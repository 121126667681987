export function formatDate(dateStr) {
    const date = new Date(dateStr);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    const formattedDate = `${day}.${month}.${year}`;

    return formattedDate;
}

export function formatDateWithTime(dateStr) {
    const date = new Date(dateStr);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${day}/${month}/${year} - ${hours}:${minutes}`;
}

export const toDateValue = (date) =>
    date ? new Date(date).toISOString().split('T')[0] : '';

export const getCreatedAt = (secs) => {
    return new Date(secs * 1000);
};

export const getTimeDifference = (unixDate) => {
    const today = new Date();
    const date = getCreatedAt(unixDate);
    return Math.round((today.getTime() - date.getTime()) / 1000);
};

export const setDateText = (spotedAt, lang) => {
    const types = [
        [
            24 * 60 * 60,
            lang === 'ru' ? 'больше суток назад' : 'more than a day ago',
        ],
        [
            60 * 60,
            `${Math.round(spotedAt / 60 / 60)} ${lang === 'ru' ? 'часов назад' : 'hours ago'}`,
        ],
        [
            60,
            `${Math.round(spotedAt / 60)} ${lang === 'ru' ? 'минут назад' : 'minuts ago'} `,
        ],
    ];

    const type = types.find((item) => spotedAt >= item[0]);

    return type
        ? type[1]
        : lang === 'ru'
          ? 'несколько секунд назад'
          : 'a few seconds ago';
};
