import {
    CHANGE_FILTERS_ERROR,
    CHANGE_FILTERS_LOADING_START,
    CHANGE_FILTERS_SUCCESS,
    SET_AUTO_REFRESH,
    SET_FILTERS,
    TOGGLE_AUTO_REFRESH,
} from '../types';

const initialState = {
    filters: {
        min_amount: 0,
        max_amount: 1000000,
        profit: 0,
        hidden: [],
        blacklist: [],
        exchanges: [],
        hidden_time: 0,
        update_time: 2,
        blockchainFilters: {},
        sort: '',
        course_spread: 0,
        funding_kinds: [],
        estimate: [],
        blockchains: [],
    },
    currentBlockchain: '',
    loading: false,
    errors: null,
    autoRefresh: false,
};

export const filtersReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case SET_FILTERS: {
            return { ...state, filters: payload };
        }

        case CHANGE_FILTERS_SUCCESS: {
            return {
                ...state,
                filters: payload,
                loading: false,
            };
        }

        case CHANGE_FILTERS_LOADING_START: {
            return { ...state, loading: true };
        }

        case CHANGE_FILTERS_ERROR: {
            return { ...state, errors: payload, loading: false };
        }

        case SET_AUTO_REFRESH: {
            return {
                ...state,
                autoRefresh:
                    payload !== undefined ? payload : state.autoRefresh,
            };
        }

        case TOGGLE_AUTO_REFRESH: {
            return { ...state, autoRefresh: !state.autoRefresh };
        }

        default:
            return state;
    }
};
