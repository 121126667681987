import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    createPaymentLink,
    fetchRequest,
} from '../../../redux/action/fetchTools';
import MainBtn from '../../layout/MainBtn/MainBtn';
import { infoMessages, sectionPrices, sectionsFullNames } from './config';
import CurrentSection from './CurrentSection';
import NewSection from './NewSection';
import SubModal from './SubModal';
import styles from './SubscriptionBlock.module.scss';

function SubscriptionBlock() {
    const user = useSelector((state) => state.auth.user);

    const mockSub = {
        price: 200,
        sections: {
            'cex-cex': 20,
            'cex-dex': 20,
            'closed_cex-cex': 20,
            'closed_cex-dex': 20,
            cex_outflows: 'free',
            dex_tracker: 20,
            cex_tracker: 'free',
            funding: 20,
            sniper: 20,
        },
    };

    const [newSubPrice, setNewSubPrice] = useState(0);
    const [newSubscriptions, setNewSubscriptions] = useState([]);
    const [message, setMessage] = useState('');
    const [subLink, setSubLink] = useState('');
    const [shosSubModal, setShowSubModal] = useState(false);
    const [showMessage, setShowMessage] = useState(false);

    useEffect(() => {
        const initialSections = Object.entries(mockSub.sections)
            .filter(([key, value]) => value !== 'none')
            .map(([key]) => ({
                section: key,
                price: sectionPrices[key] || 0,
            }));
        setNewSubscriptions(initialSections);
        setNewSubPrice(
            initialSections.reduce((total, sub) => total + sub.price, 0)
        );
    }, []);

    const displayMessage = (msg) => {
        setMessage(msg);
        setShowMessage(true);
        setTimeout(() => setShowMessage(false), 3000);
    };

    const displaySubMessage = (msg) => {
        setSubLink(msg);
        setShowSubModal(true);
    };

    const addSubscription = (section) => {
        const isDuplicate = newSubscriptions.some(
            (sub) => sub.section === section
        );

        if (isDuplicate) {
            displayMessage(infoMessages.duplicate);
            return;
        }

        const price = sectionPrices[section] || 0;

        setNewSubscriptions((prev) => [...prev, { section, price }]);
        setNewSubPrice((prev) => prev + price);
    };

    const removeSubscription = (index) => {
        const removed = newSubscriptions[index];
        setNewSubscriptions((prev) => prev.filter((_, i) => i !== index));
        setNewSubPrice((prev) => prev - removed.price);
    };

    const submitSubscription = async () => {
        if (newSubscriptions.length === 0) {
            displayMessage(infoMessages.atLeastOne);
            return;
        }

        const subNames = newSubscriptions.map((sub) => sub.section).toString();

        const resLink = await fetchRequest(createPaymentLink, 'POST', {
            id: user.id,
            amount: newSubPrice,
            subscription: subNames,
        });

        displaySubMessage(resLink.payment_link);
    };

    return (
        <section className={styles['subscriptionBlock__container']}>
            {showMessage && (
                <div className={styles['subscriptionBlock__infoModal']}>
                    {message}
                </div>
            )}

            {shosSubModal && (
                <SubModal
                    onClose={() => {
                        setShowSubModal(false);
                    }}
                    link={subLink}
                />
            )}

            <div className={styles['subscriptionBlock__box']}>
                <h3>Текущая подписка</h3>
                <div
                    className={`${styles['subscriptionBlock__currentSections']} scrollbarDef`}
                >
                    {Object.entries(mockSub.sections).map(([key, value]) => (
                        <CurrentSection
                            key={key}
                            price={value}
                            name={sectionsFullNames[key]}
                        />
                    ))}
                </div>

                <h3 className={styles['subscriptionBlock__priceBlock']}>
                    <span>Цена: </span>
                    <span className={styles['subscriptionBlock__price']}>
                        {mockSub.price}$
                    </span>
                </h3>
            </div>

            <div className={styles['subscriptionBlock__box']}>
                <h3>Новая подписка</h3>
                <div className={styles['subscriptionBlock__newSubsContainer']}>
                    <div className={styles['subscriptionBlock__newSubsInner']}>
                        <h4>Доступные разделы</h4>
                        <div
                            className={`${styles['subscriptionBlock__newSubsBlock']} scrollbarDef`}
                        >
                            {Object.entries(sectionsFullNames).map(
                                ([sectionKey, name]) => (
                                    <div
                                        key={sectionKey}
                                        className={
                                            styles[
                                                'subscriptionBlock__newSection'
                                            ]
                                        }
                                    >
                                        <div>
                                            {name}
                                            {' - '}
                                            <span
                                                className={
                                                    styles[
                                                        'subscriptionBlock__price'
                                                    ]
                                                }
                                            >
                                                (
                                                {sectionPrices[sectionKey] || 0}
                                                $)
                                            </span>
                                        </div>

                                        <MainBtn
                                            onClick={() =>
                                                addSubscription(sectionKey)
                                            }
                                        >
                                            Добавить
                                        </MainBtn>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                    <div className={styles['subscriptionBlock__newSubsInner']}>
                        <h4>Выбранные разделы</h4>
                        <div
                            className={`${styles['subscriptionBlock__newSectionsBlock']} scrollbarDef`}
                        >
                            {newSubscriptions.map((sub, index) => (
                                <NewSection
                                    key={index}
                                    price={sub.price}
                                    section={sectionsFullNames[sub.section]}
                                    onRemove={() => {
                                        removeSubscription(index);
                                    }}
                                />
                            ))}
                        </div>
                    </div>
                </div>
                <div className={styles['subscriptionBlock__submitBox']}>
                    <h3 className={styles['subscriptionBlock__priceBlock']}>
                        <span>Итоговая цена: </span>
                        <span className={styles['subscriptionBlock__price']}>
                            {newSubPrice}$
                        </span>
                    </h3>
                    <MainBtn onClick={submitSubscription}>
                        Оформить подписку
                    </MainBtn>
                </div>
            </div>
        </section>
    );
}

export default SubscriptionBlock;
