import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useSocket } from '../../../../hooks/useSocket';
import { requestCEXOutflows } from '../../../../redux/action/cexOurflow';
import FiltersBlock from '../../../global/FiltersBlock';
import DataLoader from '../../../layout/DataLoader/DataLoader';

import {
    authConfig,
    fetchRequest,
    reportUrl,
} from '../../../../redux/action/fetchTools';
import { setAutoRefresh } from '../../../../redux/action/filters';
import { getFunding, requestFunding } from '../../../../redux/action/funding';
import ApplyPopup from '../../../layout/ApplyPopup';
import FundingListItem from './FundingListItem';
import styles from './styles.module.scss';

const FundingList = () => {
    const data = useSelector((state) => state.funding.data);
    const loading = useSelector((state) => state.funding.getLoading);
    const autoRefresh = useSelector((state) => state.filters.autoRefresh);
    const user = useSelector((state) => state.auth.user);
    const dispatch = useDispatch();

    const [isReportSended, setIsReportSended] = useState(false);

    const onSuccessFilterApply = useCallback(requestCEXOutflows, []);

    const onSendReportMessage = useCallback(
        async (Item) => {
            const reqBody = {
                kind: `funding`,
                user: user,
                object: Item,
            };

            try {
                const res = await fetchRequest(
                    reportUrl,
                    'POST',
                    reqBody,
                    authConfig()
                );

                if (res.status === 200) {
                    setIsReportSended(true);
                }
            } catch (error) {
                console.log(error);
            }
        },
        [user]
    );

    useEffect(() => dispatch(setAutoRefresh()), [dispatch]);

    useSocket(
        useCallback((payload) => dispatch(getFunding(payload)), [dispatch]),
        useCallback(() => dispatch(requestFunding()), [dispatch]),
        'funding',
        autoRefresh
    );

    return (
        <div className={styles['fundingList']}>
            <FiltersBlock onSuccessFilterApply={onSuccessFilterApply} />
            <ApplyPopup
                isOpened={isReportSended}
                onClose={() => setIsReportSended(false)}
                text="Жалоба отправлена"
            />
            {data.length > 0 ? (
                <div className={styles['fundingList__container']}>
                    {data.map((item, index) => (
                        <FundingListItem
                            key={index}
                            item={item}
                            {...item}
                            onSendReportMessage={onSendReportMessage}
                        />
                    ))}
                </div>
            ) : (
                <DataLoader loading={loading} isEmpty={data.length === 0} />
            )}
        </div>
    );
};

export default FundingList;
